<template>
  <b-container>
    <div class="d-flex justify-content-center m-3">
      <h2>報價詢問</h2>
    </div>
    <b-form class="m-3">
      <b-form-group label="勾選欲詢問價格的商品">
        <div v-for="product in productlist" :key="product.value" class="mb-1">
          <b-form-checkbox :value="product.value" v-model="selected" inline size="lg" :disabled="product.notEnabled">
            {{ product.text }}
            <b-badge @click="showDetail(product.value)" v-if="product.img1 || product.img2 || product.desc">詳細</b-badge>
          </b-form-checkbox>
        </div>
      </b-form-group>

      <div class="d-flex justify-content-center mt-4">
        <b-button variant="primary" @click="submit" :disabled="this.selected.length === 0">送出</b-button>
      </div>
    </b-form>
    <b-modal v-model="model.show" hide-header centered no-close-on-backdrop no-close-on-esc>
      <div class="w-100 d-flex justify-content-center">{{ model.text }}</div>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button variant="secondary" size="sm" @click="close"> 確認 </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="detail.show" hide-footer centered :title="detail.title">
      <div class="w-100 d-flex justify-content-center mb-2" v-if="detail.img1">
        <b-img :src="detail.img1" fluid></b-img>
      </div>
      <div class="w-100 d-flex justify-content-center mb-2" v-if="detail.img2">
        <b-img :src="detail.img2" fluid></b-img>
      </div>
      <div class="w-100 d-flex" v-html="detail.desc"></div>
    </b-modal>
    <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" rounded="sm" no-wrap></b-overlay>
  </b-container>
</template>

<script>
import liff from "@line/liff";
import quoteMessage from "../templates/quoteMessage";
export default {
  data() {
    return {
      loading: true,
      model: {
        show: false,
        text: "",
      },
      detail: {
        show: false,
        img1: "",
        img2: "",
        desc: "",
        title: "",
      },
      lineId: null,
      selected: [],
      productlist: [],
      quotationlist: [],
      liffId: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      document.title = "報價詢問";
      await this.getConfig();
      await liff.init({ liffId: this.liffId });
      await liff.getProfile().then((profile) => {
        this.lineId = profile.userId;
      });
      //this.lineId = "1596332"
      await this.checkRegister();
      await this.getQuotation();
      await this.getProductlist();
      this.loading = false;
    },
    async getConfig() {
      await this.$http.get("/configuration").then((res) => {
        this.liffId = res.data.liff_quote;
      });
    },
    async getProductlist() {
      await this.$http.get("/product/list").then((res) => {
        if (res.data.success) {
          this.productlist = res.data.content.map((item) => ({
            text: item.no + " " + item.name,
            value: item.id,
            notEnabled: this.quotationlist.includes(item.id),
            img1: item.img1,
            img2: item.img2,
            desc: item.description,
          }));
        }
      });
    },
    async getQuotation() {
      await this.$http.get("/quotation/list", { params: { lineId: this.lineId } }).then((res) => {
        if (res.data.success) {
          this.quotationlist = res.data.content.map((p) => p.product_id);
        }
      });
    },
    submit() {
      this.loading = true;
      this.$http.post("/quotation/update", { lineId: this.lineId, id: this.selected }).then((res) => {
        if (res.data.success) {
          liff.sendMessages([quoteMessage]).then(() => {
            liff.closeWindow();
          });
        } else {
          this.model.text = "錯誤，請洽管理員";
          this.model.show = true;
        }
        this.loading = false;
      });
    },
    async checkRegister() {
      await this.$http.get("/user/confirm", { params: { id: this.lineId } }).then((res) => {
        if (res.data.success) {
          if (!res.data.content) {
            this.model.text = "請先進行註冊";
            this.model.show = true;
          }
        }
      });
    },
    close() {
      liff.closeWindow();
    },
    showDetail(value) {
      let data = this.productlist.find((p) => p.value === value);
      if (data.notEnabled === false) {
        if (this.selected.includes(value)) this.selected = this.selected.filter((p) => p != value);
        else this.selected.push(value);
      }
      this.detail.title = data.text;
      this.detail.img1 = data.img1;
      this.detail.img2 = data.img2;
      this.detail.desc = data.desc;
      this.detail.show = true;
    },
  },
};
</script>