export default {
  type: "flex",
  altText: "估價單",
  contents: {
    type: "bubble",
    body: {
      type: "box",
      layout: "vertical",
      contents: [
        {
          type: "text",
          text: "已送出報價詢問",
          weight: "bold",
          size: "xl",
          align: "center",
          contents: [],
        },
        {
          type: "separator",
          margin: "lg",
        },
        {
          type: "text",
          text: "感謝您的詢問，會盡速回覆您！",
          margin: "md",
          color: "#666666",
        },
      ],
    },
  },
};
